@mixin include-font($file, $family, $weight: normal, $style: normal) {
  @font-face {
    font-weight: $weight;
    font-family: $family;
    font-style: $style;
    src: url('#{$file}.woff2') format('woff2'), url('#{$file}.woff') format('woff');
    font-display: swap;
  }
}

@include include-font('../../fonts/CeraPro-Bold', 'Cera-Pro', 700);
@include include-font('../../fonts/CeraPro-Regular', 'Cera-Pro', 400);
@include include-font('../../fonts/CeraPro-Medium', 'Cera-Pro', 500);
