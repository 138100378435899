.toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  margin-top: calc(13px - 30px);

  &-pin {
    box-sizing: border-box;
    display: inline-block;
    width: 61px;

    & input,
    & input:active {
      border: 0;
      height: 0;
      opacity: 0;
      outline: none;
      position: absolute;
      left: -5000px;
      width: 0;
    }
    & label {
      background: #17113399;
      border: 1px solid #FFFFFF33;
      border-radius: 50px;
      box-sizing: border-box;
      cursor: pointer;
      display: block;
      height: 36px;
      padding: 5px;
      position: relative;
      transition: 0.3s ease-out;
      width: 100%;
    }
    & label::before {
      background: #40A1FF;
      border-radius: 32px;
      box-shadow: 0px 9px 17px 0px #40A1FF42;
      content: "";
      display: block;
      height: 26px;
      left: 5px;
      position: absolute;
      top: 4px;
      right: auto;
      transition: 0.25s ease-in-out;
      width: 25px;
      z-index: 2;
    }
    & label::after {
      content: "";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transition: 0.25s ease-in-out;
      width: 61px;
      z-index: 1;
    }
    & input:checked + label::after {
      width: 100%;
    }
    & input:checked + label::before {
      background: #9338EE;
      box-shadow: 0px 9px 17px 0px #9338EE42;
      left: calc(100% - 30px);
    }
  }

  &-slogan {
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #FFFFFF4D;
    cursor: pointer;

    &__active {
      color: #FFFFFF;
    }
  }
}

@media screen and (max-width: 1200px)
{
  .toggle {
    justify-content: center;

    &-pin {
      margin: 0 8px;
    }
  }
}
@media screen and (max-width: 1355px) {
  .toggle {
    justify-content: center;

    &-pin {
      margin: 0 8px;
    }
  }
}

@media screen and (max-width: 600px) {
  .toggle {
    &-pin {
      width: 41px;

      & label {
        height: 26px;
      }
      & label::before {
        content: "";
        height: 16px;
        width: 15px;
      }
      & label::after {
        content: "";
        width: 41px;
      }
      & input:checked + label::before {
        left: calc(100% - 20px);
      }
    }

    &-slogan {
      font-size: 14px;
      line-height: 18px;
    }
  }
}
