.preloader__wrapper {
  position: relative;
  &::after {
    overflow: hidden;
    border-radius: 50%;
    width: 87px;
    height: 87px;
    content: "";
    position: absolute;
    left: calc(50% - 45px);
    top: calc(50% - 45px);
    border-top: 2px solid rgba(255, 255, 255, 0.2);
    border-right: 2px solid rgba(255, 255, 255, 0.2);
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
    border-left: 2px solid rgba(255, 255, 255, 0.2);
  }
}

.preloader__overlay::before {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  content: "";
  width: 100%;
  height: 100%;

  // background: #171133;
  // opacity: 0.6;

  background: rgba(23, 17, 51, 0.01);
  backdrop-filter: blur(10px) opacity(0.25);
  transition: backdrop-filter 0.25s ease-in-out;

  @supports not (
    (backdrop-filter: blur(60px)) or (-webkit-backdrop-filter: blur(60px))
  ) {
    & {
      background: rgba(23, 17, 51, 0.95);
    }
  }
}

.preloader,
.preloader::after {
  overflow: hidden;
  border-radius: 50%;
  width: 84px;
  height: 84px;
}

.preloader:before {
}

.preloader {
  margin: 60px auto;
  position: relative;
  z-index: 10;
  text-indent: -9999em;
  border-top: 4px solid rgba(255, 255, 255, 0);
  border-right: 4px solid rgba(255, 255, 255, 0);
  border-bottom: 4px solid white;
  border-left: 4px solid white;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: loadRound 1.1s infinite linear;
  animation: loadRound 1.1s infinite linear;

  &__text-slogan {
    font-family: inherit;
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    text-align: center;
    color: white;
    position: absolute;
    z-index: 10;
    left: 0;
    width: 100%;
    bottom: -65%;
  }
}
@-webkit-keyframes loadRound {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loadRound {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.form-enter__preloader--top-20 {
  top: -20px;
}
